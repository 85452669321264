import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import hiperbarLogo from '../../images/hiperbar.jpg';
import uzemegeszsegugyLogo from '../../images/uzemegeszsegugy.png';
import './Home.css';

const Home = () => (
  <div>
    <Container>
      <Row>
        <Col xs={12}  md={6}>
          <a href="Info">
            <Row>
              <Col xs={12} align="center" className="logo-column">
                  <img src={hiperbarLogo} className="logo" alt="hiperbarLogo" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="next-column">
                <Button className="next-button">Tovább</Button>
              </Col>
            </Row>
          </a>
        </Col>
        <Col xs={12} md={6}>
          <a href="http://www.uzemegeszsegugy.hu">
            <Row>
              <Col xs={12} align="center" className="logo-column">
                  <img src={uzemegeszsegugyLogo} className="logo" alt="uzemegeszsegugyLogo" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="next-column">
                <Button className="next-button">Tovább</Button>
              </Col>
            </Row>
          </a>
        </Col>
      </Row>
    </Container>
  </div>
);
export default Home;
