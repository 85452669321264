import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';

const Gdpr = () => (
  <Container >
    <Row className="tp-col-l">
      <Col xs={12}>
        <h4>TÁJÉKOZTATÓ COOKIE-K (SÜTIK) HASZNÁLATÁRÓL</h4>
      </Col>
    </Row>
    <Row className="tp-col">
      <Col xs={12}>
        <p>A cookie (süti) olyan kis méretű szöveges fájl, adatsor, amit a weboldal látogatójának internetes hozzáférést biztosító eszközén (számítógép, mobiltelefon stb.) hozhat létre a weboldalt megjelenítő program.
        A sütik kizárólag adatokat tartalmaznak, nem tartalmaznak futtatható állományokat, vírusokat, illetve kémprogramokat és nem férnek hozzá a felhasználó merevlemezének adataihoz.
        Minden weboldal használ sütiket.</p>
        <p>A cookie-k célja lehetővé tenni a felhasználó internetezésre használt eszközének felismerését, a felhasználó igényeihez igazított tartalom megjelenítését.</p>
        <p>A cookie-k anonim látogatói statisztikák generálását segítik elő, a felhasználók személyes kilétéről nem adnak információkat – személyes adatkezelés tehát nem történik.<br/>
        Kétféle cookie típust használunk: az ideiglenes sütiket a számítógép memóriája addig tárolja, ameddig a felhasználó a honlapot böngészi, automatikusan törlődnek, amint bezárja a böngészőt, illetve lejár a böngészési folyamatra meghatározott idő.
        A tartós sütiket a számítógép addig tárolja, amíg a felhasználó nem törli azokat.</p>
        <p>Amennyiben ön saját beállításaiban letiltotta a cookie-kat, böngészéséről nem tárolunk adatokat.</p>
        <p>Jelen honlap Google Analytics szolgáltatást használ, mely szintén alkalmaz cookie-kat. Ezek összesítve tárolják a felhasználókra vonatkozó adatokat (egyedi látogatás, visszatérő látogatás, ezek száma, honnan érkezett a felhasználó, mennyi időt töltött böngészéssel stb.), ezáltal a honlapfejlesztésben, a felhasználói élmény javításában segítenek bennünket.</p>
        <p>A cookie-ban nem tárolunk a személyek azonosítására alkalmas adatokat.</p>
        <p>Ahhoz, hogy böngészési élménye teljes legyen és az oldal minden funkciója működjön, kérjük, engedélyezze a sütiket (cookie), illetve ne kapcsolja ki azok használatát.</p>
      </Col>
    </Row>
    <Row className="tp-col-l">
      <Col xs={12}>
        <h4>ADATKEZELÉSI IRÁNYELVEK (GDPR)</h4>
      </Col>
    </Row>
    <Row className="tp-col">
      <Col xs={12}>
        <p>A személyes adatok kezelése tekintetében a főbb irányadó jogszabályok a természetes személyeknek, a személyes adatok kezeléséről szóló az Európai Parlament és a Tanács (EU) 2016/679 rendelete (GDPR),
        Magyarország Alaptörvénye (2011. április 27.), a polgári törvénykönyvről szóló 2013. évi V. törvény (Ptk.), az egészségügyi adatok és a hozzájuk kapcsolódó személyes adatok kezeléséről és védelméről szóló 1997. évi XLVII. törvény,
        az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény, a személy- és vagyonvédelmi, valamint a magánnyomozói tevékenység szabályairól szóló 2005. évi CXXXIII. törvény,
         illetve a munka törvénykönyvéről szóló 2012. évi I. törvény.</p>
        <p>A személyes adatok kezelése minden esetben az érintett önkéntes hozzájárulásán alapul. Az érintett személy által közvetlenül megadott adatok, továbbá online felületeinek használatával kapcsolatos adatok,
         illetve a bárki által elérhető, nyilvánosságra hozott adatok kezelését a hatályos jogszabályok és irányelvek szerint valósítjuk meg.</p>
        <p>Az adatok kezelése az igénybe vett egészségügyi szolgáltatás biztosítása szempontjából indokolt időtartamig tart,
         illetve addig, míg az érintett a személyes adatainak törlését nem kéri.</p>
        <p>Az adatkezelő online felületei működése során automatikusan, technikailag rögzítésre kerülő adatok a generálódásuktól számítva azok működésének biztosítása szempontjából indokolt időtartamig kerülnek tárolásra.
         Az adatkezelő biztosítja, hogy ezen automatikusan rögzített adatok egyéb személyes felhasználói adatokkal – a törvény által kötelezővé tett esetek kivételével – össze nem kapcsolhatók.
          Minden olyan esetben, ahol az adatkezelő az eredeti adatfelvétel céljától eltérő célra kívánja felhasználni az érintett személyes adatait, erről az érintettet előzetesen tájékoztatja és ehhez előzetes,
           kifejezett hozzájárulását megszerezi, továbbá lehetőséget biztosít számára, hogy az ilyen, eredeti céltól eltérő felhasználást megtiltsa.</p>
      </Col>
    </Row>
    <Row className="tp-col-l">
      <Col xs={12}>
        <h4>IMPRESSZUM</h4>
      </Col>
    </Row>
    <Row className="tp-col">
      <Col xs={12}>
        A honlap az 1.sz. Üzemegészségügyi Kft. tulajdona. <a href="/info">Kapcsolatfelvétel</a>
      </Col>
    </Row>
    <Row className="tp-col">
      <Col xs={12}>
        Tárhelyszolgáltató:  <a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">aws.amazon.com</a>
      </Col>
    </Row>
  </Container>
);
export default Gdpr;
