import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { MdEmail, } from "react-icons/md";
import { FaFacebookSquare, FaPhone } from "react-icons/fa";
import './Info.css';
import Iframe from 'react-iframe'

const Info = () => (
  <div>

    <Container>
      <Row>
        <Col xs={12} align="center" className="tp-col">
          <h2>Az oldal fejlesztés alatt áll</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={5} className="tp-col">
          <Row>
            <Col xs={12} className="tp-col">
              <h4>Kezelések:</h4>
            </Col>
            <Col xs={12} className="tp-col">
              Kezelések menetéről, időpontról az alábbi elérhetőségeinken érdeklődjön.
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="tp-col">
              <hr/>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="tp-col">
              <h4>Elérhetőség:</h4>
            </Col>
            <Col xs={12} className="tp-col">
              <a href="tel:+3614652376"><FaPhone size={26}/>+361 465 23 76</a>
            </Col>
            <Col xs={12} className="tp-col">
              <a href="mailto:hyperbar@uzemegeszsegugy.hu"><MdEmail size={26}/> hyperbar@uzemegeszsegugy.hu</a>
            </Col>
            <Col xs={12} className="tp-col">
              <a href="https://www.facebook.com/hiperbarkozpont/"><FaFacebookSquare size={26}/> facebook.com/hiperbarkozpont</a>
            </Col>
            <Col xs={12} className="tp-col">
              További információ: <a href="http://www.uzemegeszsegugy.hu/webset32.cgi?Uzemegeszsegugy%40%40HU%40%4032%40%40GOOGLEBOT&fbclid=IwAR0EiXkSqjk8G3MnZn6P8FS5kB_lnw1XF-TQ2j4eZAGk5mDm-2ih6S3IAPQ">www.uzemegeszsegugy.hu</a>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={7} className="tp-col">
          <Iframe width="100%" height="400" frameBorder="0" src="https://maps.google.com/maps?q=bart%C3%B3k%20b%C3%A9la%20%C3%BAt%20152%2Fc&t=&z=13&ie=UTF8&iwloc=&output=embed" />
        </Col>
      </Row>
    </Container>
  </div>
);
export default Info;
