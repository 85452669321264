import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import CookieConsent from "react-cookie-consent";
import './index.css';
import App from './containers/App/App';
import Home from './containers/Home/Home';
import Info from './containers/Info/Info';
import Gdpr from './containers/Gdpr/Gdpr';
import logo from './images/logoInvert.png';
import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <BrowserRouter>
    <App>
      <Navbar variant="dark"
            className="menu navbar-right"
            expand="lg"
            sticky="top">
        <Container>
          <Navbar.Brand href="/" className="white-text"><img src={logo} className="navbar-logo" alt="navbar-logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="right"/>
              <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="mr-auto justify-content-end" style={{ width: "100%" }}>
                  <Nav.Link href="/adatkezelesi-iranyelvek">ADATKEZELÉSI IRÁNYELVEK</Nav.Link>
                </Nav>
              </Navbar.Collapse>
        </Container>
      </Navbar>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/info" component={Info} />
        <Route path="/adatkezelesi-iranyelvek" component={Gdpr} />
      </Switch>
      <CookieConsent
         buttonText="Megértettem">
          A weboldal felhasználóbarát működése érdekében cookie-kat használunk. <a href="/adatkezelesi-iranyelvek" rel="noopener noreferrer">Bővebben</a>
      </CookieConsent>
    </App>
  </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
